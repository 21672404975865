export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'home'
};

export const siteSettingsRoutes = {
  name: 'Site Settings',
  to: '/admin/settings',
  exact: true,
  icon: 'link'
};

export const announcementRoutes = {
  name: 'Announcements',
  to: '/admin/announcement',
  exact: true,
  icon: 'bell'
};

export const bodRoutes = {
  name: 'Board of Directors',
  to: '/admin/bod',
  exact: true,
  icon: 'users'
};

export const faqMembershipRoutes = {
  name: 'FAQ - Membership',
  to: '/admin/faqmembership',
  exact: true,
  icon: 'book'
};

export const faqLoansRoutes = {
  name: 'FAQ - Loans',
  to: '/admin/faqloan',
  exact: true,
  icon: 'book'
};

export const faqDepositRoutes = {
  name: 'FAQ - Deposits',
  to: '/admin/faqdeposit',
  exact: true,
  icon: 'book'
};

export const downloadRoutes = {
  name: 'Downloads',
  to: '/admin/download',
  exact: true,
  icon: 'download'
};

export const galleryRoutes = {
  name: 'Gallery',
  to: '/admin/gallery',
  exact: true,
  icon: 'camera'
};

export const administratorRoutes = {
  name: 'Administrators',
  to: '/admin/administrator',
  exact: true,
  icon: 'users'
};

export const emailRoutes = {
  name: 'Email Notifications',
  to: '/admin/bulkemails',
  exact: true,
  icon: 'users'
};

export const loanRateRoutes = {
  name: 'Loan Rates',
  to: '/admin/loanrate',
  exact: true,
  icon: 'stream'
};

export const aboutRoutes = {
  name: 'About',
  to: '/about',
  children: [
    { to: '/about/birsala', name: 'About BIRSALA' },
    { to: '/about/bod', name: 'Board of Directors' },
    { to: '/about/privacy', name: 'Data Privacy Policy' }
  ]
};

export default [homeRoutes, siteSettingsRoutes, announcementRoutes, loanRateRoutes, bodRoutes, faqMembershipRoutes, faqLoansRoutes, faqDepositRoutes, downloadRoutes, galleryRoutes, emailRoutes, administratorRoutes];
