import React from 'react';
import { CardImg, Card, CardBody } from 'reactstrap';
import error from '../../assets/img/generic/error.jpg';
import Api from '../../helpers/api.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default class Error500 extends React.Component {
  componentDidMount() {
    Api.request().logout();
  }

  render() {
    return (
      <Card className="text-center">
        <CardImg src={error} top />
        <CardBody className="p-4">
          <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">Whoops, something went wrong!</p>
          <hr />
          <p>
            Try refreshing the page, or going back and attempting the action again.
          </p>
          <Link className="btn btn-primary btn-sm mt-3" to="/">
            <FontAwesomeIcon icon="home" className="mr-2" />
            Take me home
          </Link>
        </CardBody>
      </Card>
    );
  }
}