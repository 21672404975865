import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { CardImg, Card, CardBody } from 'reactstrap';
import error from '../../assets/img/generic/error.jpg';

const Error404 = () => (
  <Card className="text-center">
    <CardImg src={error} top />
    <CardBody className="p-4">
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        The page you're looking for is not found.
      </p>
      <hr />
      <p>
        Make sure the address is correct and that the page hasn't moved.
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        Take me home
      </Link>
    </CardBody>
  </Card>
);

export default Error404;
