import jwt_decode from 'jwt-decode';
import http from './http.js';
import config from '../config.js';

const ACCESS_TOKEN = 'access_token';
const ACCESS_USER_ID = 'access_id';
const ACCESS_EMAIL = 'access_email';
const ACCESS_FULL_NAME = 'access_full_name';

const CURRENT_BULK_EMAIL = 'bulk_email';

export default class Repository {
  constructor(path) {
    this.apiUrl = config.apiUrl;
    this.path = path;
  }

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  getAccessUserId() {
    return localStorage.getItem(ACCESS_USER_ID);
  }

  getAccessEmail() {
    return localStorage.getItem(ACCESS_EMAIL);
  }

  getAccessFullName() {
    return localStorage.getItem(ACCESS_FULL_NAME);
  }

  getBulkEmail() {
    return localStorage.getItem(CURRENT_BULK_EMAIL);
  }

  setAccessToken(accessToken) {
    var decoded = jwt_decode(accessToken);
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(ACCESS_USER_ID, decoded.id);
    localStorage.setItem(ACCESS_EMAIL, decoded.username);
    localStorage.setItem(ACCESS_FULL_NAME, decoded.fullname);
  }

  setAccessEmail(e) {
    localStorage.setItem(ACCESS_EMAIL, e);
  }

  setAccessFullName(e) {
    localStorage.setItem(ACCESS_FULL_NAME, e);
  }

  setBulkEmail(e) {
    localStorage.setItem(CURRENT_BULK_EMAIL, e);
  }

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCESS_USER_ID);
    localStorage.removeItem(ACCESS_EMAIL);
    localStorage.removeItem(ACCESS_FULL_NAME);
  }

  request() {
    let req = http.request();
    req.addHeader('X-Application-ID', 'birsala-website');
    if (this.getAccessToken()) {
      req.addHeader('Authorization', ['Bearer', this.getAccessToken()].join(' '));
    }
    return req;
  }

  url() {
    return this.getAbsolutePath(this.path);
  }

  getAbsolutePath(...args) {
    args.unshift(this.apiUrl);
    return args.join('');
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }
}
