import React from 'react';
import _s from 'underscore.string';
import PubSub from 'pubsub-js';

export default class TopNotificationBar extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.notification = null;
		this.state = { notice: null }
	}

	componentDidMount() {
		this._isMounted = true;

		this.notification = {
			target: document.getElementById('top-notification-bar'),
			animation: null
		};

		this.initSubscriptions();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	initSubscriptions() {
		let self = this;

		PubSub.subscribe('notification', (msg, data) => {
			if (/dismiss/.test(msg)) {
				if (this._isMounted) {
					self.setState({
						notice: null
					});
				}
				return;
			}

			let notice = data;

			if (/(warn|error)$/.test(msg)) {
				if (typeof data === "object" && data.statusCode) {
					notice = _s.sprintf("There was a problem processing your request.<br /><strong>%s</strong>: %s", data.statusCode, data.error);
				}

				if (notice == null || typeof notice === 'undefined') {
					notice = "There was a problem processing your request.";
				}
			}

			if (this._isMounted) {
				self.setState({
					notice: notice
				});
			}
		});

		PubSub.subscribe('notification.success', (msg, data) => {
			self.resetNotification();
			self.notification.target.classList.add('alert-notice-success');
			self.dismissNotification();
		});

		PubSub.subscribe('notification.info', (msg, data) => {
			self.resetNotification();
			self.notification.target.classList.add('alert-notice-info');
			self.dismissNotification();
		});

		PubSub.subscribe('notification.warn', (msg, data) => {
			self.resetNotification();
			self.notification.target.classList.add('alert-notice-warning');
		});

		PubSub.subscribe('notification.error', (msg, data) => {
			self.resetNotification();
			self.notification.target.classList.add('alert-notice-danger');
		});

		PubSub.subscribe('notification.dismiss', (msg, data) => {
			self.dismissNotification(100);
		});
	}

	resetNotification() {
		clearTimeout(this.notification.animation);
		this.notification.target.className = 'notification';
		this.notification.target.style.display = "block";
	}

	dismissNotification(ttl = null) {
		if (ttl === null) {
			ttl = 8000;
		}

		this.notification.animation = setTimeout(function () {
			this.notification.target.className = 'notification';
			this.notification.target.style.display = "none";
		}.bind(this), ttl);
	}

	render() {		
		let style = {
			position: "fixed",
			width: "100%",
			top: "0",
			left: "0",
			padding: "20px 30px",
			zIndex: "3000",
			display: "none",
			borderRadius: "0",
			backgroundColor: 'white',
			borderBottom: '1px solid lightgray'
		}

		if (this.state.notice) {
			style.display = "block";
		}

		return (
			<div id="top-notification-bar" className="notification" style={style}>
				<span onClick={this.dismissNotification.bind(this, 100)}
					className="dismiss text-muted" style={{ cursor: "pointer", float: 'right' }}
					aria-label="dismiss this notification">
					<span>X</span>
				</span>
				<div className="notice" role="alert" aria-live="assertive" dangerouslySetInnerHTML={{ __html: this.state.notice }}></div>				
			</div>
		);
	}
}
