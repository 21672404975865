import React, { useContext } from 'react';
import { Label, Nav, Navbar, NavbarToggler, Collapse } from 'reactstrap';
import AppContext from '../../context/AppContext';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';
import Api from '../../helpers/api.js';

let fullname = Api.request().getAccessFullName();

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);

  return (
    <Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
      <Logo at="navbar-top" width={60} bottom={false} id="topLogo" />      
      <NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
        <Collapse navbar>
        <Nav navbar className="align-items-center ml-auto">
          <Label style={{ float: 'right' }}>Welcome, <b><a href="/admin/myprofile" title="">{fullname}</a></b>!</Label>      
          <ProfileDropdown />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarTop;
