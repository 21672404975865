import React, { useEffect } from 'react';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import TopNotificationBar from './TopNotificationBar';
import Api from '../helpers/api.js';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
const Landing = loadable(() => import('../components/landing/Landing'));
const About = loadable(() => import('../components/about/BIRSALA'));
const BOD = loadable(() => import('../components/about/BOD'));
const Privacy = loadable(() => import('../components/about/Privacy'));
const Membership = loadable(() => import('../components/membership/Membership'));
const Loans = loadable(() => import('../components/loans/Loans'));
const LoanApplication = loadable(() => import('../components/loans/LoanApplication'));
const Deposits = loadable(() => import('../components/deposits/Deposits'));
const Download = loadable(() => import('../components/download/Downloads'));
const Gallery = loadable(() => import('../components/gallery/Gallery'));
const Contact = loadable(() => import('../components/contact/Contact'));
const Announcement = loadable(() => import('../components/announcement/Announcement'));
const ForgotPassword = loadable(() => import('../components/common/ForgotPassword'));

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (Api.request().isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />)} />
);

const Layout = () => {
  useEffect(() => {
    Landing.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/about/birsala" exact component={About} />
        <Route path="/about/bod" exact component={BOD} />
        <Route path="/about/privacy" exact component={Privacy} />
        <Route path="/membership" exact component={Membership} />
        <Route path="/loans" exact component={Loans} />
        <Route path="/loans/apply" exact component={LoanApplication} />
        <Route path="/deposits" exact component={Deposits} />
        <Route path="/download" exact component={Download} />
        <Route path="/gallery" exact component={Gallery} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/announcement/:id" exact component={Announcement} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <AuthRoute path="/admin" component={DashboardLayout} />
        <Route path="/errors" exact component={ErrorLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
      <TopNotificationBar />
    </Router>
  );
};

export default Layout;
