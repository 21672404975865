import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faExchangeAlt,
  faAngleRight,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faUsers,
  faPiggyBank,
  faMoneyBill,
  faEdit,
  faArrowUp,
  faKey,
  faImages,
  faEnvelope,
  faDownload
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar
} from '@fortawesome/free-brands-svg-icons';

import { faLightbulb, faObjectUngroup, faPaperPlane } from '@fortawesome/free-regular-svg-icons';

library.add(
  // Solid
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faExchangeAlt,
  faAngleRight,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faUsers,
  faPiggyBank,
  faMoneyBill,

  // Brand
  faFacebook,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,

  // Regular
  faLightbulb,
  faObjectUngroup,
  faPaperPlane,

  faEdit,
  faArrowUp,
  faArrowDown,
  faKey,
  faImages,
  faEnvelope,
  faDownload
);
