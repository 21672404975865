export default class Logger {
  static warn(...args) {
    args.unshift('%cWARN%c ');
    console.warn.call(
      console,
      args.join(''),
      'background-color: orange; color: white; padding: 1px 10px',
      'color: black'
    );
  }

  static log(...args) {
    if (typeof console.log != 'undefined') {
      console.log.apply(console, args);
    }
  }

  static error(...args) {
    args.unshift('%cERROR%c ');
    if (typeof console.error != 'undefined') {
      console.error.call(
        console,
        args.join(''),
        'background-color: red; color: white; padding: 1px 10px',
        'color: black'
      );
    } else {
      Logger.log(args);
    }
  }

  static info(...args) {
    args.unshift('%cINFO%c ');
    if (typeof console.info != 'undefined') {
      console.info.call(
        console,
        args.join(''),
        'background-color: cobalt; color: white; padding: 1px 10px',
        'color: black'
      );
    } else {
      Logger.log(args);
    }
  }

  static debug(...args) {
    args.unshift('%cDEBUG%c ');
    if (typeof console.debug != 'undefined') {
      console.debug.call(
        console,
        args.join(''),
        'background-color: green; color: white; padding: 1px 10px',
        'color: black'
      );
    } else {
      Logger.log(args);
    }
  }

  static trace(...args) {
    args.unshift('%cTRACE%c ');
    if (typeof console.log != 'undefined') {
      console.log.call(console, args.join(''), 'background-color: rgb(200,200,200); padding: 1px 10px', 'color: black');
    }
  }
}
