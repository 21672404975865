import $ from 'jquery';
import Promise from 'bluebird';
import Log from './log.js';
import _s from 'underscore.string';

class HttpRequest {
  constructor() {
    this.headers = {};
    this.processData = true;
    this.data = null;
    this.async = true;
    this.xhr = null;
    this.url = null;
  }

  send(method = 'GET') {
    return new Promise((resolve, reject) => {
      this.xhr = $.ajax(this.url, {
        data: this.data,
        headers: this.headers,
        contentType: this.contentType,
        processData: this.processData,
        type: method,
        async: this.async
      })
        .done(r => {
          Log.trace(_s.sprintf('Request Successful - %s %s', method, this.url), this);
          resolve(r);
        })
        .fail((xhr, status, error) => {
          Log.error(_s.sprintf('Request Failed - %s %s %s %s', method, this.url, status, error), this);
          if (xhr.status === 401) {
            // Unauthorized
            window.location.href = '/errors/500';
          } else {
            reject({
              statusCode: xhr.status,
              status,
              error,
              responseBody: xhr.responseText ? JSON.parse(xhr.responseText) : ''
            });
          }
        });
    });
  }

  setData(v) {
    this.data = v;
    return this;
  }

  setProcessData(v) {
    this.processData = v;
    return this;
  }

  setJSON(v) {
    return this.setProcessData(false)
      .addHeader('Content-Type', 'application/json; charset=utf-8')
      .setData(JSON.stringify(v, null, 2));
  }

  addHeader(k, v) {
    this.headers[k] = v;
    return this;
  }

  setUrl(v) {
    this.url = v;
    return this;
  }

  GET() {
    return this.send();
  }

  POST() {
    return this.send('POST');
  }

  PUT() {
    return this.send('PUT');
  }

  DELETE() {
    return this.send('DELETE');
  }

  HEAD() {
    return this.send('HEAD');
  }

  PATCH() {
    return this.send('PATCH');
  }
}

export default class Http {
  static request() {
    return new HttpRequest();
  }
}
