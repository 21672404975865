import Repository from './repository.js';
import Promise from 'bluebird';
import { Base64 } from 'js-base64';

class ApiRequest extends Repository {
  login(email, passsword) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/auth/login`))
        .addHeader('Authorization', ['Bearer', Base64.encode(email + ':' + passsword)].join(' '))
        .GET()
        .then(r => {
          if (r.succeeded) {
            this.setAccessToken(r.object);
          }
          return resolve(r);
        }, reject);
    });
  }

  logout() {
    return this.removeAccessToken();
  }

  getUserProfile(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/user/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  updateUserProfile(p) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/user/`))
        .setJSON(p)
        .PATCH()
        .then(r => resolve(r), reject);
    });
  }

  updateUserPassword(old, p) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/updateuserpassword/` + encodeURIComponent(old)))
        .setJSON(p)
        .PATCH()
        .then(r => resolve(r), reject);
    });
  }

  getStringSetting(name) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/stringsetting/` + encodeURIComponent(name)))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getHomeContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/home`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getHomeAnnouncementsContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/homeannouncements`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getLatestAnnouncementsContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/latestannouncements`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }


  getServicesContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/services`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getFooterContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/footer`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAboutContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/about`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAnnouncementContent(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/announcement/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getBODContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/bod`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getPrivacyContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/privacy`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getMembershipContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/membership`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getLoansContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/loans`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getDepositContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/deposit`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getDownloadContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/download`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getDownload(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/download/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getGalleryContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/gallery`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getGalleryItems(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/gallery/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getCalculatorContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/calculator`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  computeMonthlyAmortization(c) {
    return new Promise((resolve, reject) => {
      this.request().setUrl(this.getAbsolutePath(`/v1/common/calculator/compute`))
        .setJSON(c)
        .POST()
        .then(r => resolve(r), reject);
    });
  }

  sendContactUsEmail(c) {
    return new Promise((resolve, reject) => {
      this.request().setUrl(this.getAbsolutePath(`/v1/common/contact/send`))
        .setJSON(c)
        .POST()
        .then(r => resolve(r), reject);
    });
  }

  forgotPassword(c) {
    return new Promise((resolve, reject) => {
      this.request().setUrl(this.getAbsolutePath(`/v1/common/forgotpassword/send`))
        .setJSON(c)
        .POST()
        .then(r => resolve(r), reject);
    });
  }

  getLoanApplicationContent() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/common/content/loanapplication`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  sendLoanApplication(c) {
    return new Promise((resolve, reject) => {
      this.request().setUrl(this.getAbsolutePath(`/v1/common/loanapplication/send`))
        .setJSON(c)
        .POST()
        .then(r => resolve(r), reject);
    });
  }

  getAdminSettings() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/settings`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAdminSetting(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/setting/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  updateSetting(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/setting`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

  getAdminBoardOfDirectors() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/bods`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  getAdminBoardOfDirector(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/bod/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addBoardOfDirector(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/bod`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateBoardOfDirector(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/bod`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteBoardOfDirector(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/bod/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
	}

  getAdminFAQMemberships() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqmemberships`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAdminFAQMembership(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqmembership/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addFAQMembership(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqmembership`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateFAQMembership(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqmembership`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteFAQMembership(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqmembership/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
	}

  getAdminFAQLoans() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqloans`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAdminFAQLoan(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqloan/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addFAQLoan(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqloan`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateFAQLoan(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqloan`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteFAQLoan(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqloan/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
	}

  getAdminFAQDeposits() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqdeposits`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAdminFAQDeposit(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/faqdeposit/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addFAQDeposit(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqdeposit`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateFAQDeposit(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqdeposit`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteFAQDeposit(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/faqdeposit/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  moveOrdinalUpFAQMembership(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/faqmembership/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownFAQMembership(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/faqmembership/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
  }
  
  moveOrdinalUpFAQLoan(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/faqloan/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownFAQLoan(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/faqloan/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
  }
  
  moveOrdinalUpFAQDeposit(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/faqdeposit/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownFAQDeposit(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/faqdeposit/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}
  
  moveOrdinalUpBoardOfDirector(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/bod/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownBoardOfDirector(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/bod/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
  }
  
  getAdministrators() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/administrators`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  getAdministrator(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/administrator/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addAdministrator(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/administrator`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateAdministrator(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/administrator`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteAdministrator(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/administrator/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }

  getAdminAnnouncements() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/announcements`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  getAdminAnnouncement(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/announcement/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addAnnouncement(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/announcement`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateAnnouncement(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/announcement`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteAnnouncement(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/announcement/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  getAdminGalleries() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/galleries`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  getAdminGallery(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/gallery/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addGallery(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/gallery`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateGallery(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/gallery`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteGallery(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/gallery/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  getAdminGalleryItems(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/galleryitems/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  addGalleryItem(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/galleryitem`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }

	deleteGalleryItem(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/galleryitem/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  moveOrdinalUpGalleryItem(id, galleryId) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/galleryitem/` + id + `/` + galleryId))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownGalleryItem(id, galleryId) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/galleryitem/` + id + `/` + galleryId))
				.PATCH()
				.then(r => resolve(r), reject);
			});
  }

  sendBulkEmail(c, currentEmail) {
    return new Promise((resolve, reject) => {
      this.request().setUrl(this.getAbsolutePath(`/v1/admin/sendbulkemail`))
        .setJSON(c)
        .POST()
        .then(r => {console.log(c); localStorage.setItem('bulk_email', currentEmail); resolve(r);}, reject);
    });
  }

  getAdminLoanRates() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/loanrates`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }  

  getAdminLoanRate(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/loanrate/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addLoanRate(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/loanrate`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateLoanRate(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/loanrate`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteLoanRate(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/loanrate/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  getAdminDownloads() {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/downloads`))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  getAdminDownload(id) {
    return new Promise((resolve, reject) => {
      this.request()
        .setUrl(this.getAbsolutePath(`/v1/admin/download/` + id))
        .GET()
        .then(r => {
          return resolve(r);
        }, reject);
    });
  }

  addDownload(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/download`))
				.setJSON(c)
				.POST()
				.then(r => resolve(r), reject);
			});
  }
  
  updateDownload(c) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/download`))
				.setJSON(c)
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	deleteDownload(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/download/` + id))
				.DELETE()
				.then(r => resolve(r), reject);
			});
  }
  
  moveOrdinalUpDownload(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinalup/download/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
	}

	moveOrdinalDownDownload(id) {
		return new Promise((resolve, reject) => {
			this.request().setUrl(this.getAbsolutePath(`/v1/admin/moveordinaldown/download/` + id))
				.PATCH()
				.then(r => resolve(r), reject);
			});
  }
}

export default class Api {
  static request() {
    return new ApiRequest();
  }
}
