import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../common/Avatar';
import team3 from '../../assets/img/team/profile.jpg';

const ProfileDropdown = () => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav className="pr-0">
      <Avatar src={team3} />
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-card">
      <div className="bg-white rounded-soft py-2">
        <DropdownItem tag={Link} to="/admin/myprofile">
          <FontAwesomeIcon icon="user" />&nbsp;&nbsp;My Profile
        </DropdownItem>
        <DropdownItem tag={Link} to="/admin/changepassword">
          <FontAwesomeIcon icon="key" />&nbsp;&nbsp;Change Password
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/admin/logout">
          <FontAwesomeIcon icon="lock" />&nbsp;&nbsp;Logout
            </DropdownItem>
      </div>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default ProfileDropdown;
